import { BrowserWindow, Experiments } from '@wix/thunderbolt-symbols'
import { OSType } from '@wix/thunderbolt-ssr-api'

export const prefersReducedMotion = (
	browserWindow: BrowserWindow,
	experiments: Experiments,
	os: OSType,
	requestUrl = ''
) => {
	const isReducedMotionExperimentOpen = experiments['specs.thunderbolt.reducedMotion']
	const isWindows = os === 'Windows' // todo - this will later depend on UoU decision using a new a11y prompt
	const shouldForce = requestUrl.toLowerCase().includes('forcereducedmotion')
	return (
		shouldForce ||
		(browserWindow && isReducedMotionExperimentOpen && !isWindows
			? browserWindow.matchMedia('(prefers-reduced-motion: reduce)').matches
			: false)
	)
}
